<template>
    <template v-if="!displayForm('editOrderInvoice')">
        <div class="w-full border border-gray-200 dark:border-gray-700 rounded-lg flex items-center py-2 px-3">
            <div class="flex flex-wrap items-start w-full">
                <item-text title="Rēķina nr." :text="item.invoice.invoice_uuid" />
                <item-text title="Rēķina datums" :text="item.invoice.invoice_date_formatted" />
                <item-text title="Sagatavots elektroniski" :text="item.invoice.electronic_document ? 'Jā' : 'Nē'" />
                <Badge v-if="item.invoice.display_payment_date" :text="'Apmaksāts ' + item.invoice.display_payment_date"
                       :className="`bg-green-600 text-white  mr-2 mb-2 md:mb-0 md:mt-5`"
                />
            </div>

            <div class="flex">
                <Button icon="pencil" @click="showForm('editOrderInvoice')" />
                <Button icon="download" @click="downloadInvoicePdf"/>
                <Button icon="open-in-new" @click="openInvoice()" />
            </div>
        </div>
    </template>

    <template v-if="displayForm('editOrderInvoice')">
        <EditOrderInvoice :order="item" />
    </template>
</template>

<script>
import {mapGetters} from "vuex"
import ItemText from "@/components/Components/ItemText"
import EditOrderInvoice from "@/components/Orders/OrderInvoice/EditOrderInvoice"
import Badge from "../../Components/Badge.vue";

export default {
    name: "ShowOrderInvoice",
    props: ['item'],
    components: {
        ItemText,
        EditOrderInvoice,
      Badge
    },
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showForm(formName){
        this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName);
        },
        downloadInvoicePdf() {
            this.$store.dispatch('getOrderInvoicePdf',  this.item)
        },
        openInvoice() {
            let routeData = this.$router.resolve({path: '/invoices/' + this.item.id});
            window.open(routeData.href, '_blank');
        }        
    }
}
</script>

<style>

</style>