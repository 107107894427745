<template>
    <span class="whitespace-nowrap inline-flex items-center py-0.5 rounded-full text-sm font-medium transition duration-500 ease-in-out" :class="[className, small ? 'leading-[18px] text-sm px-2' : 'leading-[26px] px-3']">
      {{ text }}
      <template v-if="button">
        <button @click="onClick" class="w-5 h-5 bg-gray-400 hover:bg-gray-50 dark:hover:bg-gray-7700 rounded-full flex items-center justify-center ml-3 my-1 text-gray-200 transition duration-300 ease-in-out">
          <XIcon class="w-3 h-3" />
        </button>
      </template>
    </span>
</template>

<script>
import {
  XIcon,
} from "@heroicons/vue/solid";

export default {
  name: "Badge",
  components: {
    XIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: null
    },
    button: {
      type: Boolean,
      default: false
    },
    clickFn: {
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.clickFn) {
        this.clickFn();
      }
    }
  }
}
</script>

<style>
</style>